import { template as template_d4945480044149d4b79c7df952128142 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d4945480044149d4b79c7df952128142(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
