import { template as template_b5d6b481754146f1a4d3c26b06e89a42 } from "@ember/template-compiler";
const WelcomeHeader = template_b5d6b481754146f1a4d3c26b06e89a42(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
