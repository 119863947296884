import { template as template_b2671b1ca9814162a34b10c7547b0e08 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b2671b1ca9814162a34b10c7547b0e08(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
